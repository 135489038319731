var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@material-ui/core";
import { GridTripleDotsVerticalIcon, } from "@mui/x-data-grid-pro";
import { BlueGridErrorOverlay } from "./BlueGridErrorOverlay";
import { BlueGridLoadingOverlay } from "./BlueGridLoadingOverlay";
import { BlueGridNoResultsOverlay } from "./BlueGridNoResultsOverlay";
import { BlueGridNoRowsOverlay } from "./BlueGridNoRowsOverlay";
import { BlueGridPagination } from "./BlueGridPagination";
import { BlueGridToolbar } from "./BlueGridToolbar";
export function useBlueGridComponents(_a) {
    var _b = _a.components, _c = _b === void 0 ? {} : _b, { ToolbarLeft, ToolbarRight } = _c, providedComponents = __rest(_c, ["ToolbarLeft", "ToolbarRight"]), _d = _a.componentsProps, _e = _d === void 0 ? {} : _d, { toolbar: toolbarProps, pagination: paginationProps, searchField: searchFieldProps, toolbarLeft: toolbarLeft, toolbarRight: toolbarRight, columnMenu: columnMenuProps, noRowsOverlay: noRowsOverlayProps, noResultsOverlay: noResultsOverlayProps } = _e, componentsProps = __rest(_e, ["toolbar", "pagination", "searchField", "toolbarLeft", "toolbarRight", "columnMenu", "noRowsOverlay", "noResultsOverlay"]), { name, enableExport, disableColumnsButton, error, hasFilteredOutRows, searchColumn, searchOperator } = _a;
    const components = Object.assign({ Toolbar: BlueGridToolbar, Pagination: BlueGridPagination, LoadingOverlay: BlueGridLoadingOverlay, NoRowsOverlay: BlueGridNoRowsOverlay, NoResultsOverlay: BlueGridNoResultsOverlay, ErrorOverlay: BlueGridErrorOverlay, ColumnResizeIcon: BlueColumnResizeIcon, ColumnUnsortedIcon: BlueUnsortedIcon, ColumnSortedDescendingIcon: BlueDescendingIcon, ColumnSortedAscendingIcon: BlueAscendingIcon, 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ColumnMenuIcon: (props) => (_jsx(GridTripleDotsVerticalIcon, Object.assign({}, props, { "data-testid": "grid-column-menu-icon" }))) }, providedComponents);
    const [NoRowsOverlayResolved, noRowsOverlayPropsResolved] = (() => {
        if (error)
            return [components.ErrorOverlay, componentsProps.errorOverlay];
        if (hasFilteredOutRows)
            return [components.NoResultsOverlay, noResultsOverlayProps];
        return [components.NoRowsOverlay, noRowsOverlayProps];
    })();
    return {
        components: Object.assign(Object.assign({}, components), { NoRowsOverlay: NoRowsOverlayResolved }),
        componentsProps: Object.assign({ toolbar: Object.assign({ gridName: name, LeftComponents: ToolbarLeft, RightComponents: ToolbarRight, enableExport, defaultColumn: searchColumn, defaultOperator: searchOperator, disableColumnsButton,
                searchFieldProps,
                toolbarLeft,
                toolbarRight }, toolbarProps), pagination: Object.assign({ gridName: name }, paginationProps), columnMenu: Object.assign({ "data-testid": "grid-column-menu" }, columnMenuProps), noRowsOverlay: noRowsOverlayPropsResolved }, componentsProps),
    };
}
function BlueColumnResizeIcon() {
    return (_jsx(SvgIcon, { width: "1", height: "28", viewBox: "0 0 1 28", fill: "none", children: _jsx("rect", { width: "1", height: "28", fill: "inherit" }) }));
}
function BlueUnsortedIcon() {
    return (_jsxs(SvgIcon, { width: "16", height: "16", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M5.00001 12C5.00001 12.5523 5.44772 13 6.00001 13C6.55229 13 7.00001 12.5523 7.00001 12L7.00001 6.41421L8.29291 7.70711C8.68343 8.09763 9.3166 8.09763 9.70713 7.70711C10.0977 7.31658 10.0977 6.68342 9.70713 6.29289L6.70712 3.29289C6.51958 3.10536 6.26523 3 6.00001 3C5.73479 3 5.48044 3.10536 5.2929 3.29289L2.29289 6.29289C1.90237 6.68342 1.90237 7.31658 2.29289 7.70711C2.68342 8.09763 3.31659 8.09763 3.70711 7.70711L5.00001 6.41421L5.00001 12Z", fill: "inherit" }), _jsx("path", { d: "M15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8L13 13.5858L11.7071 12.2929C11.3166 11.9024 10.6834 11.9024 10.2929 12.2929C9.90239 12.6834 9.90239 13.3166 10.2929 13.7071L13.2929 16.7071C13.4805 16.8946 13.7348 17 14 17C14.2652 17 14.5196 16.8946 14.7071 16.7071L17.7071 13.7071C18.0977 13.3166 18.0977 12.6834 17.7071 12.2929C17.3166 11.9024 16.6835 11.9024 16.2929 12.2929L15 13.5858L15 8Z", fill: "inherit" })] }));
}
function BlueDescendingIcon() {
    return (_jsx(SvgIcon, { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.0485 12.3515C20.5172 12.8201 20.5172 13.5799 20.0485 14.0485L12.8485 21.2485C12.3799 21.7172 11.6201 21.7172 11.1515 21.2485L3.95148 14.0485C3.48285 13.5799 3.48285 12.8201 3.95148 12.3515C4.42011 11.8828 5.17991 11.8828 5.64853 12.3515L10.8 17.5029L10.8 3.59999C10.8 2.93725 11.3373 2.39999 12 2.39999C12.6627 2.39999 13.2 2.93725 13.2 3.59999L13.2 17.5029L18.3515 12.3515C18.8201 11.8828 19.5799 11.8828 20.0485 12.3515Z", fill: "inherit" }) }));
}
function BlueAscendingIcon() {
    return (_jsx(SvgIcon, { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.95147 11.6485C3.48284 11.1799 3.48284 10.4201 3.95147 9.95147L11.1515 2.75147C11.6201 2.28284 12.3799 2.28284 12.8485 2.75147L20.0485 9.95147C20.5172 10.4201 20.5172 11.1799 20.0485 11.6485C19.5799 12.1172 18.8201 12.1172 18.3515 11.6485L13.2 6.49705L13.2 20.4C13.2 21.0627 12.6627 21.6 12 21.6C11.3373 21.6 10.8 21.0627 10.8 20.4L10.8 6.49705L5.64853 11.6485C5.1799 12.1172 4.4201 12.1172 3.95147 11.6485Z", fill: "inherit" }) }));
}
