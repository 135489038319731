import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles, TextField as Field, } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    input: {
        border: "1px solid #D1D5DB",
        boxSizing: "border-box",
        background: "rgba(255, 255, 255, 1)",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
        fontSize: 14,
        paddingLeft: 10,
        paddingRight: 10,
        "&:hover": {
            borderColor: theme.palette.grey[500],
        },
    },
    inputSingle: {
        height: 36,
    },
    inputMultiline: {
        overflowY: "scroll",
    },
    focus: {
        boxShadow: `0px 2px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px ${theme.palette.primary.main}`,
        borderColor: "transparent !important",
        background: "white",
    },
    error: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #DC2626",
        borderColor: "transparent",
        background: "white",
    },
    labelRoot: {
        transform: "none",
        position: "static",
        "& + div": {
            marginTop: 7,
        },
    },
    helperText: {
        marginTop: 0,
    },
}));
export function TextField(props) {
    var _a;
    const classes = useStyles();
    return (_jsx(Field, Object.assign({}, props, { variant: "standard", fullWidth: true, InputProps: Object.assign(Object.assign({}, props.InputProps), { className: clsx({
                [classes.input]: true,
                [classes.inputSingle]: !props.multiline,
                [classes.inputMultiline]: props.multiline,
            }), classes: Object.assign(Object.assign({}, (_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.classes), { focused: classes.focus, error: classes.error }), disableUnderline: true }), InputLabelProps: Object.assign(Object.assign({}, props.InputLabelProps), { shrink: true, classes: { root: classes.labelRoot } }), FormHelperTextProps: {
            className: classes.helperText,
        } })));
}
